import React from "react";

import Fb from "../images/fb.svg";
import VK from "../images/vk.svg";
import Dzen from "../images/dzen.svg";
// import Spot from "../images/spot.svg";

import InstaDark from "../images/insta-dark.svg";
import TwitDark from "../images/twit-dark.svg";

import { Link } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import { localizedPath } from "./Header";

export const FooterNav = ({ location, dark }) => {
  const { locale, defaultLocale } = useIntl();

  return (
    <div className="footer-nav">
      <div className="mt-24 mb-24 footer-line"></div>
      <div className="row">
        <div className="col-12">
          <div className="row justify-content-between align-items-end">
            <div className="col-auto">
              <div className="row">
                <div className="col-6 col-md-auto footer-link-container">
                  <Link
                    className="footer-link mr-24"
                    to={localizedPath(defaultLocale, locale, "/terms/")}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.terms" />
                  </Link>
                  <Link
                    className="footer-link mr-24"
                    to={localizedPath(
                      defaultLocale,
                      locale,
                      "/policy/privacy/"
                    )}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.privacy" />
                  </Link>
                  <Link
                    className="footer-link mr-24"
                    to={localizedPath(defaultLocale, locale, "/policy/cookie/")}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.cookies" />
                  </Link>
                </div>
                <div className="col-6 col-md-auto footer-link-container">
                  <Link
                    className="footer-link mr-24"
                    to={localizedPath(defaultLocale, locale, "/musicians/")}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.mus" />
                  </Link>
                  <Link
                    className="footer-link mr-24"
                    to={localizedPath(
                      defaultLocale,
                      locale,
                      "/music-for-youtube/"
                    )}
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.musYT" />
                  </Link>
                </div>
                {locale === "ru" && (
                  <div className="col-6 col-md-auto footer-link-container">
                    <Link
                      className="footer-link mr-24"
                      to={localizedPath(defaultLocale, locale, "/requisite/")}
                      style={{ textDecoration: "none" }}
                    >
                      Реквизиты
                    </Link>
                  </div>
                )}
                <div className="col-6 col-md-auto footer-link-container">
                  <a
                    className="footer-link mr-24"
                    href="mailto:info@duqqy.com"
                    style={{ textDecoration: "none" }}
                  >
                    <FormattedMessage id="footer.link.contact" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-auto">
              <div className="row align-items-center footer-nav-row">
                <div className="col-auto">
                  <ul className="nav-list footer-nav-list main-list row align-items-center tt-uc">
                    {locale === "ru" ? (
                      <>
                        <li className="col-auto nav-item">
                          <a target="_blank" href="https://vk.com/duqqysound">
                            <img src={VK} alt="Duqqy" />
                          </a>
                        </li>
                        <li className="col-auto nav-item">
                          <a target="_blank" href="https://dzen.ru/duqqysound">
                            <img src={Dzen} alt="Duqqy" />
                          </a>
                        </li>
                      </>
                    ) : (
                      <li className="col-auto nav-item">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/duqqysound"
                        >
                          <img src={Fb} alt="Duqqy" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-24 copyright">©2023 DUQQY</div>
    </div>
  );
};
