import React from "react";

import numeral from "numeral";

export const useHelpers = () => {
  const sortByKey = (array, key, type) => {
    return array.sort(function (a, b) {
      let x = a[key];
      let y = b[key];

      if (type === "desc") {
        return x > y ? -1 : x < y ? 1 : 0;
      } else {
        return x < y ? -1 : x > y ? 1 : 0;
      }
    });
  };

  const moneyFormat = (val) => {
    if (!val && val !== 0) return "N/A";

    return numeral(val).format(val < 10 ? "0.[00]a" : "0,0");
  };

  const getUrl = (zone) => {
    const isLocal = window.location.href.indexOf("local") > -1;

    return isLocal
      ? `https://develop-booster.duqqy.${zone}`
      : `https://booster.duqqy.${zone}`;
  };

  const setCookie = (name, value, zone) => {
    const date = new Date();
    const dateCopy = new Date(date.getTime());
    const expires = dateCopy.setHours(date.getHours() + 24 * 30);

    document.cookie = `${name}=${value};domain=.duqqy.${zone};path=/;expires=${new Date(
      expires
    )}`;
  };

  const setLangCookie = (value) => {
    const date = new Date();
    const dateCopy = new Date(date.getTime());
    const expires = dateCopy.setFullYear(date.getFullYear() + 2);

    document.cookie = `user_lang=${value};domain=.duqqy.com;path=/;expires=${new Date(
      expires
    )}`;
  };

  return {
    sortByKey,
    moneyFormat,
    getUrl,
    setCookie,
    setLangCookie,
  };
};
