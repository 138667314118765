import { Link } from "gatsby";
import React from "react";
import { FormattedMessage } from "react-intl";

export const Cookie = ({ onOkClick }) => {
  return (
    <div className="cookie-modal">
      <p>
        <FormattedMessage id="cookie.desc" />
        <Link to="/policy/cookie" style={{ color: "#FBCE14", fontWeight: 500 }}>
          <FormattedMessage id="cookie.btn" />
        </Link>
      </p>
      <button
        className="btn yellow"
        style={{ marginLeft: "24px", fontSize: "16px" }}
        onClick={() => {
          window.localStorage.setItem("accept_cookie", 1);
          onOkClick();
        }}
      >
        Ok
      </button>
    </div>
  );
};
