import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "gatsby";

import Check from "../images/check.svg";

import { Arrow } from "./Icons";
import classNames from "classnames";
import { localizedPath } from "./Header";
import { useHelpers } from "../hooks/helpers";

export const LanguageSwitcher = ({ locales, location = {} }) => {
  const { locale: currentLocale, defaultLocale, formatMessage } = useIntl();
  const { setLangCookie } = useHelpers();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  function localizedPath(defaultLocale, locale, path) {
    const [, base] = path.split(`/`);
    if (locale === defaultLocale || base === locale) return path;
    return base.length === 2 ? path.replace(base, locale) : `/${locale}${path}`;
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  if (typeof window === "undefined") {
    return null;
  }

  return (
    <>
      <div className="language-container">
        <div
          ref={ref}
          className="language-current-item"
          onClick={() => setOpen(!isOpen)}
        >
          <div className="mr-8">{currentLocale}</div>
          <Arrow style={{ transform: `rotate(${isOpen ? 0 : "180deg"})` }} />
        </div>
        <div className={classNames("language-drop", { active: isOpen })}>
          {locales.map((locale) => (
            <Link
              key={locale}
              className={`lang-item ${
                locale === currentLocale ? "active" : ""
              }`}
              onClick={() => setLangCookie(locale)}
              to={
                locale === defaultLocale
                  ? window.location.pathname.replace(`/${currentLocale}`, "")
                  : localizedPath(
                      defaultLocale,
                      locale,
                      window.location.pathname
                    )
              }
            >
              <div>
                <span>{formatMessage({ id: locale })}</span>
              </div>
              {locale === currentLocale && <img src={Check} alt="Duqqy" />}
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};
