export const isBrowser = () => typeof window !== "undefined";

export const getStatus = () =>
  isBrowser() && window.localStorage.getItem("status")
    ? JSON.parse(window.localStorage.getItem("status"))
    : false;

const setStatus = (status) =>
  window.localStorage.setItem("status", JSON.stringify(status));

export const fetchStatus = async (zone) => {
  const { status } = await fetch(`https://auth.duqqy.${zone}/auth/status/`, {
    credentials: "include",
  });

  setStatus(status);

  return status;
};

export const isLoggedIn = () => {
  const status = getStatus() === 200;
  return status;
};
