import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "gatsby";

import { Logo, Nav } from "./Icons";
import { isLoggedIn, fetchStatus, getStatus } from "../services/auth";
import { useHelpers } from "../hooks/helpers";
import { LanguageSwitcher } from "./LanguageSwitcher";

export function localizedPath(defaultLocale, locale, path) {
  const [, base] = path.split(`/`);
  if (locale === defaultLocale || base === locale || locale === "ru")
    return path;
  return `/${locale}${path}`;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const Header = ({ location }) => {
  const { setCookie } = useHelpers();
  const { locale, defaultLocale } = useIntl();
  const [zone] = React.useState(locale === "ru" ? "ru" : "com");
  const [url, setUrl] = React.useState("");
  const [status, setStatus] = React.useState(getStatus());
  const [authUrl, setAuthUrl] = React.useState(
    `https://auth.duqqy.${zone}/signin`
  );
  const [path, setPath] = React.useState("");
  const [isAna, setAna] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  const fetchUserStatus = async () => {
    const status = await fetchStatus(zone);

    setStatus(status);
  };

  React.useEffect(() => {
    const isAna = location.pathname.indexOf("analyt") > -1;
    setAna(isAna);

    if (isAna) {
      setAuthUrl(authUrl + "?next=analytics");
    }

    const lastUrl = isAna
      ? `https://analytics.duqqy.${zone}/search`
      : getCookie("last_visited_url") || `https://booster.duqqy.${zone}`;
    setUrl(lastUrl.replace("develop-", "").replace("-local", ""));

    if (!isLoggedIn()) {
      fetchUserStatus();
    }

    setPath(window.location.pathname.split("/")[1]);
  }, []);

  return (
    <>
      <div className={isOpen ? "header open" : "header"}>
        <div>
          <div className="row">
            <div className="col-12">
              <div className="row justify-content-between align-items-center header-row">
                <div className="col-auto" style={{ position: "relative" }}>
                  <Link to="/" className="logo df ai-center">
                    <Logo />
                  </Link>
                </div>
                <div className="col-auto">
                  <div className="row nav-toggle-content">
                    <div className="col-auto nav-col">
                      <ul className="nav-list main-list header-list row align-items-center tt-uc">
                        <li className="col-auto nav-item">
                          <Link
                            activeClassName="active"
                            to={localizedPath(
                              defaultLocale,
                              locale,
                              "/musicians/"
                            )}
                          >
                            <FormattedMessage id="footer.link.mus" />
                          </Link>
                        </li>
                        <li className="col-auto nav-item">
                          <Link
                            to={localizedPath(
                              defaultLocale,
                              locale,
                              "/music-for-youtube/"
                            )}
                            activeClassName="active"
                          >
                            <FormattedMessage id="footer.link.musYT" />
                          </Link>
                        </li>
                        <li className="col-auto nav-item">
                          <Link
                            to={localizedPath(
                              defaultLocale,
                              locale,
                              "/pricing/"
                            )}
                            activeClassName="active"
                          >
                            <FormattedMessage id="pricing" />
                          </Link>
                        </li>
                        {!!status && (
                          <li className="col-auto nav-item">
                            <a
                              href={status === 200 ? url : authUrl}
                              onClick={() =>
                                setCookie(
                                  "utm_referer",
                                  window.location.pathname,
                                  zone
                                )
                              }
                            >
                              <FormattedMessage
                                id={status === 200 ? "jump" : "signin"}
                              />
                            </a>
                          </li>
                        )}
                        <li className="col-auto nav-item">
                          <a
                            href={`https://auth.duqqy.${zone}/${
                              isAna
                                ? "add_channel?next=analytics&state=our_partner"
                                : "signup"
                            }`}
                            onClick={() =>
                              setCookie(
                                "utm_referer",
                                window.location.pathname,
                                zone
                              )
                            }
                          >
                            <FormattedMessage id={"signup"} />
                          </a>
                        </li>
                        <li className="col-auto nav-item">
                          {locale !== "ru" && (
                            <LanguageSwitcher
                              location={location}
                              locales={["en", "es", "pt"]}
                            />
                          )}
                        </li>
                      </ul>
                    </div>

                    <div className="col-auto text-end nav-btn-col">
                      <div className="nav-btn" onClick={() => setOpen(!isOpen)}>
                        <Nav />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
