import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { IntlProvider } from "react-intl";

import { getCurrentLangKey } from "ptz-i18n";

import Header from "./Header";
import "../styles/index.sass";

import enMessages from "../intl/en";
import ruMessages from "../intl/ru";
import esMessages from "../intl/es";
import ptMessages from "../intl/pt";
import { Cookie } from "./Cookie";

function getLocaleMessages(locale) {
  let messages = enMessages;

  switch (locale) {
    case "ru":
      messages = ruMessages;
      break;
    case "es":
      messages = esMessages;
      break;
    case "pt":
      messages = ptMessages;
      break;
    default:
      messages = enMessages;
  }

  return messages;
}

const getCookie = (name) => {
  if (typeof window === "undefined") return null;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

const Layout = ({ children, location, locale = "en" }) => {
  const currentLocale = getCookie("user_lang") || locale;

  const [isShowCookie, setShowCookie] = React.useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          i18nLocales {
            defaultLocale
            locales
          }
        }
      }
    }
  `);

  const messages = getLocaleMessages(currentLocale);

  const { locales, defaultLocale } = data.site.siteMetadata.i18nLocales;
  const currentUserLocale = getCurrentLangKey(
    locales,
    defaultLocale,
    location.pathname
  );

  const setCookie = (name, value) => {
    const zone = window.location.hostname.indexOf("ru") > -1 ? "ru" : "com";
    const date = new Date();
    const dateCopy = new Date(date.getTime());
    const expires = dateCopy.setHours(date.getHours() + 24 * 30);

    document.cookie = `${name}=${value};domain=.duqqy.${zone};path=/;expires=${new Date(
      expires
    )}`;
  };

  const setCookies = () => {
    let utmTags = window.location.search;

    if (utmTags.indexOf("utm") > -1) {
      utmTags
        .replace("?", "")
        .split("&")
        .forEach((cookie) => {
          const [key, value] = cookie.split("=");
          setCookie(key, value);
        });
    }
  };

  const checkCookieAccept = () => {
    if (!window.localStorage.getItem("accept_cookie")) {
      setShowCookie(true);
    }
  };

  React.useEffect(() => {
    setCookie("utm_referer", location.pathname);

    const isFirstVisit = getCookie("utm_first_page");

    if (!isFirstVisit) {
      setCookie("utm_first_page", location.pathname);
    }
  }, [location]);

  React.useEffect(() => {
    setTimeout(() => {
      checkCookieAccept();
    }, 2000);

    setCookies();
  }, []);

  return (
    <>
      <IntlProvider
        defaultLocale={defaultLocale}
        locale={currentLocale}
        messages={messages}
      >
        <Header siteTitle={data.site.siteMetadata.title} location={location} />
        {children}

        {isShowCookie && <Cookie onOkClick={() => setShowCookie(false)} />}
      </IntlProvider>
    </>
  );
};

export default Layout;
